import React from "react";
import { graphql } from "gatsby";
import Blog from "../components/Blog";

class BlogPage extends React.Component {
  render() {
    const { location, data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.posts.edges.map((edge) => edge.node);

    return <Blog location={location} siteTitle={siteTitle} posts={posts} />;
  }
}

export default BlogPage;

export const pageQuery = graphql`
  query Blog {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { glob: "/blog/**" } } }
    ) {
      edges {
        node {
          ...MarkdownRemarkData
        }
      }
    }
  }
`;
