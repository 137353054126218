import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import PrettyList from "../common/PrettyList";
import styles from "./Blog.module.scss";

class Blog extends React.Component {
  render() {
    const { location, siteTitle, posts } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Blog" keywords={["blog"]} />
        <Hero title="Blog" />
        <PrettyList
          className={styles.posts}
          items={posts.map((node) => ({
            title: node.frontmatter.title || node.fields.slug,
            description: node.frontmatter.description || node.excerpt,
            slug: node.fields.slug,
          }))}
        />
      </Layout>
    );
  }
}

Blog.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  posts: PropTypes.array.isRequired,
};

export default Blog;
